import axios from 'axios';

let doPost = (oUrl, oData, ok, err, end) => {
	ok = ok || '';
	err = err || '';
	end = end || '';
	let userInfo = sessionStorage.getItem('userInfo');
	axios.post('/api'+oUrl, oData, {
		headers: {
			'Content-Type': 'application/json;chartset=uft-8',
			'Authorization': userInfo == null ? '' : JSON.parse(userInfo).token
		}
	}).then((res) => {
		ok(res);
	}).catch((e) => {
		err(e);
	}).finally(() => {
		end && end();
	});
}

let doPostWithDataMidApi = (oUrl, oData, ok, err, end) => {
	ok = ok || '';
	err = err || '';
	end = end || '';
	let userInfo = sessionStorage.getItem('userInfo');
	axios.post('/data-mid-api'+oUrl, oData, {
		headers: {
			'Content-Type': 'application/json;chartset=uft-8',
			'Authorization': userInfo == null ? '' : JSON.parse(userInfo).token
		}
	}).then((res) => {
		ok(res);
	}).catch((e) => {
		err(e);
	}).finally(() => {
		end && end();
	});
}

let doYxyPost = (oUrl, oData, ok, err, end) => {
	ok = ok || '';
	err = err || '';
	end = end || '';
	let userInfo = sessionStorage.getItem('userInfo');
	axios.post('Xsy'+oUrl, oData, {
		headers: {
			'Content-Type': 'application/json;',
			// 'Authorization': userInfo == null ? '' : JSON.parse(userInfo).token,
      'token': 'b1431d1fd4bc607fb956124ebe4ad808e532bda8'
		}
	}).then((res) => {
		ok(res);
	}).catch(() => {
		err();
	}).finally(() => {
		end();
	});
}


let doGet = (oUrl, oData, ok, err, end) => {
	ok = ok || '';
	err = err || '';
	end = end || '';
	let userInfo = sessionStorage.getItem('userInfo');
	axios.get('/api'+oUrl, {
		params: oData,
		headers: {
			'Content-Type': 'application/json;chartset=uft-8',
			'Authorization': userInfo == null ? '' : JSON.parse(userInfo).token
		}
	}).then((res) => {
		ok(res);
	}).catch(() => {
		err();
	}).finally(() => {
		end();
	});
}

let exportOut = (oUrl, oData, name, ok, err, end) => {
	ok = ok || '';
	err = err || '';
	end = end || '';
	let userInfo = sessionStorage.getItem('userInfo');
	axios({
		url:'/api' + oUrl, 
		method:'post',
		data: oData,
		headers: {
			'Content-Type': 'application/json;chartset=uft-8',
			'Authorization': userInfo == null ? '' : JSON.parse(userInfo).token,
		},
		responseType: 'blob',
	}).then((res) => {
		let a = document.createElement('a')
		let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
		let href = window.URL.createObjectURL(blob) // 创建下载的链接
		a.href = href
		let date = new Date()
		let str = ""
		str += date.getFullYear()
		let month = date.getMonth() + 1
		if(month < 10){
			str += "0"+month
		}else{
			str += month
		}
		str += date.getDate()
		str += date.getHours()
		str += date.getMinutes()
		str += date.getSeconds()
		a.download = name + '_' + str + '.xls' // 下载后文件名
		document.body.appendChild(a)
		a.click() // 点击下载
		document.body.removeChild(a) // 下载完成移除元素
		window.URL.revokeObjectURL(href) // 释放掉blob对象
		ok();
	}).catch(() => {
		err();
	}).finally(() => {
		end();
	});
}

let exportOut2 = (oUrl, oData, name, ok, err, end) => {
	ok = ok || '';
	err = err || '';
	end = end || '';
	let userInfo = sessionStorage.getItem('userInfo');
	axios({
		url:'/cloudApi' + oUrl, 
		method:'post',
		data: oData,
		headers: {
			'Content-Type': 'application/json;chartset=uft-8',
			'Authorization': userInfo == null ? '' : JSON.parse(userInfo).token,
			'satoken':sessionStorage.getItem('cloudToken'),
		},
		responseType: 'blob',
	}).then((res) => {
		let a = document.createElement('a')
		let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
		let href = window.URL.createObjectURL(blob) // 创建下载的链接
		a.href = href
		let date = new Date()
		let str = ""
		str += date.getFullYear()
		let month = date.getMonth() + 1
		if(month < 10){
			str += "0"+month
		}else{
			str += month
		}
		str += date.getDate()
		str += date.getHours()
		str += date.getMinutes()
		str += date.getSeconds()
		a.download = name + '_' + str + '.xls' // 下载后文件名
		document.body.appendChild(a)
		a.click() // 点击下载
		document.body.removeChild(a) // 下载完成移除元素
		window.URL.revokeObjectURL(href) // 释放掉blob对象
		ok();
	}).catch(() => {
		err();
	}).finally(() => {
		end();
	});
}

export default {
	doPost,
	doPostWithDataMidApi,
	doGet,
	exportOut,
	exportOut2,
  doYxyPost
}
